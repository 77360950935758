/**
 * Properties for API 
 */
    try {
        //const protocol = window.location.protocol;
        const host = window.location.host;
       
        switch(host){
            case "localhost:3000":
                global.base_url = "http://localhost:8080/";
                break;
                case "complaint.development.guay.digital":
                    global.base_url = "https://api.development.guay.digital/v2/";
                    break;   
                case "complaint.staging.guay.digital":
                    global.base_url = "https://api.staging.guay.digital/v2/";
                    break;
                case "canal-denuncia.guay.digital":
                    global.base_url = "https://api.guay.digital/v2/";
                    break;
            default:
                global.base_url = "https://api.development.guay.digital/v2/";
                break;    
        }
    } catch (error) {
        console.log(error);
    }
    
     



import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Register from './components/complaint/Register';
import NotFound from './components/general/NotFound'

function App() {
  
  return (
    <BrowserRouter>
    <Switch>
     <Route path="/error/pagina-no-encontrada" component={NotFound} exact /> 
      <Route path="/:enterprise" component={Register} exact />
     
    </Switch>
  </BrowserRouter>
  );
}

export default App;

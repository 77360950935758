import React from "react";

const Header = (props) => {


    return(
        <div className="content-header row">
         <div className="col-md-2"></div> 
        <div className="content-header-left col-md-2 col-12 mb-1">
        <img src={ props.logo } className="w-75" alt={props.name}/>
        </div>
        <div className="col-md-4 text-center">
         <img src="../img/logo_canal.png" className="w-40 mt-5pn" alt="Canal de Denuncia"/>
          <h2 className="text-base-guay" >{props.name}</h2>
        </div>
        <div className="content-header-right col-md-2 col-12 text-right">
        
        <img src="../img/logo_guay_color.png" className="w-70" alt="logo"/>
        </div>
      </div> 
    )
}
export default Header;

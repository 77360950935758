import React from "react";
 
const NotFound = () => {

    return(
        <section className="flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="col-md-4 col-10 p-0">
                    <div className="card-header bg-transparent border-0">
                        <h2 className="error-code text-center mb-2">400</h2>
                        <h3 className="text-uppercase text-center">Bad Request</h3>
                    </div>
                    <div className="row py-2">
                    <div className="col-12 col-sm-6 col-md-6 mb-1">
                        <a href="https://guay.digital" className="btn btn-primary btn-block"><i className="ft-home"></i> Inicio</a>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-1">
                        <a href="https://guay.digital" className="btn btn-danger btn-block"><i className="ft-search"></i> Buscar</a>
                    </div>
                </div>
                <div className="card-footer bg-transparent">
                <div className="row">
                    <p className="text-muted text-center col-12 pb-1">© <span class="year">2020</span> guay digital </p>
                    <div className="col-12 text-center">
                        
                    </div>
                </div>
            </div>
        </div> 
         
         </div>
        </section>
    )
}
export default NotFound;
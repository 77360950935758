import React, { Fragment, useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import $ from "jquery";
import Header from "../general/Header";
import Recaptcha from "react-google-invisible-recaptcha";
import ModalComponent from "../general/ModalComponent";
import "../Config";

const Register = (props) => {
  const { enterprise } = props.match.params;
  // State for form complaint
  const [complaitn, updComplait] = useState({
    item: "",
    subject: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    rol: "",
    area: "",
    message: "",
    empresa: "",
    envio: "0",
  });
  const {
    item,
    subject,
    name,
    lastName,
    email,
    phone,
    rol,
    area,
    message,
  } = complaitn;
  //  const formData = new FormData();
  // State for item select html input
  const [listItem, updItemList] = useState([]);
  // State for subject select html input
  const [listSubject, updSubjectList] = useState([]);
  // State for rol select html input
  const [listRol, updRolList] = useState([]);
  // State for area selecte html input
  const [listArea, updAreaList] = useState([]);
  // State for enterprise
  const [stateEnterprise, upEnterprise] = useState([]);
  const [listFiles, upFilesList] = useState([]);
  const [archivos, upArchivos] = useState([]);

  // API Statements
  const enterpriseModel = async (enterprise) => {
    try {
      /* var company = enterprise.replace(/[^a-zA-Z ]/g, ""); */
      var company = enterprise;
      if (company.length > 0) {
        const url =
          global.base_url +
          "complaint/enterprise?name=" +
          company;
        const response = await axios.post(url);
        if (response.data.enterpriseId) {
          upEnterprise(response.data);
          updComplait({
            ...complaitn,
            empresa: response.data.enterpriseId,
          });
        } else {
          //window.location.href = "/error/pagina-no-encontrada";
        }
      } else {
        window.location.replace("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Close Modal event
  const close = async () => {
    try {
      itemModel();
      rolModel();
      areaModel();
    } catch (error) {
      console.log(error);
    }
  };
  const itemModel = async () => {
    try {
      const url = global.base_url + "catalogs/getItemsCat";
      const response = await axios.post(url);
      updItemList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const subjectModel = async (itemid) => {
    try {
      const url =
        global.base_url +
        "catalogs/getSubjectsCat?idItem=" +
        itemid;
      const response = await axios.post(url);
      updSubjectList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const rolModel = async () => {
    try {
      const url = global.base_url + "catalogs/getRolCompCat";
      const response = await axios.post(url);
      updRolList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const areaModel = async () => {
    try {
      const url =
        global.base_url +
        "catalogs/getAreaCat?idEnterprise=" +
        stateEnterprise.enterpriseId;
      const response = await axios.post(url);
      updAreaList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Change Value form
  const getValue = (e) => {
    let value = e.target.value;

    switch (e.target.name) {
      case "envio":
        value = e.target.checked ? 1 : 0;
        break;
      case "phone":
        if (!/^\d{10}$/.test(value)) {
          $("#" + e.target.name).removeClass("border-success");
          $("#" + e.target.name).addClass("border-danger");

        } else {
          $("#" + e.target.name).removeClass("border-danger");
          $("#" + e.target.name).addClass("border-success");

        }
        break;
      case "item":
        subjectModel(e.target.value);
        break;
      default:
        if (value === "0" || e.target.value.length === 0) {
          $("#" + e.target.name).removeClass("border-success");
          $("#" + e.target.name).addClass("border-danger");
        } else {
          $("#" + e.target.name).removeClass("border-danger");
          $("#" + e.target.name).addClass("border-success");
        }
        break;
    }
    if (e.target.name === "envio") {
      if (value === 1) {
        updComplait(prevValues => ({
          ...prevValues,
          envio: value,
          name: 'ANONIMO',
          lastName: 'ANONIMO',
          email: 'ANONIMO',
          phone: '5555555555'
        }));
      } else {
        updComplait(prevValues => ({
          ...prevValues,
          envio: value,
          name: '',
          lastName: '',
          email: '',
          phone: ''
        }));
      }
    } else {
      updComplait({
        ...complaitn,
        [e.target.name]: value,
      });
    }

  };

  const renderFile = () => {
    $("#file").click();
  };

  const checkFileSize = (event) => {
    let files = event.target.files;
    let size = 5000000;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].name + " pesa más de 5MB. Intente con otro archivo.";
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      swal("Oops!", "El archivo: " + err[z], "warning");
      event.target.value = null;
    }
    return true;
  };

  const maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 4) {
      const msg = "Solo puede subir 5 imagenes al mismo tiempo";
      event.target.value = null;
      swal("Oops!", msg, "warning");
      return false;
    }
    return true;
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];

    // list allow mime type
    const types = ["image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        err[x] = files[x].name + " no contiene un formato válido. Sólo se puede subir archivos con formato jpg, png, word, excel y pdf";
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      swal("Oops!", "El archivo: " + err[z], "warning");
      event.target.value = null;
    }
    return true;
  };
  const deleteFile = (event) => {
    try {
      var $target = $(event.currentTarget);
      //let id = $target.attr("id");

      //delete listFiles[$target.attr("data-element")];
      $("#" + $target.attr("id")).hide();
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeHandler = (event) => {
    let files = event.target.files;
    var array = [];
    var data = []
    if (archivos.length > 4) {
      swal("Oops!", "Solo puede subir 5 imagenes", "warning");
      return;
    }

    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      if (archivos.length === 0) {
        let oldState = [...archivos];
        oldState[0] = files
        upArchivos(oldState);
        for (var i = 0; i < files.length; i++) {
          array = { name: files[i].name, id: files[i].lastModified };
          data.push(array);
          upFilesList(data);
        }
      } else {
        let oldState = [...archivos];
        let oldField = [...listFiles];
        oldState[archivos.length] = files
        upArchivos(oldState)
        for (var i = 0; i < files.length; i++) {
          oldField[oldField.length] = { name: files[i].name, id: files[i].lastModified };
          upFilesList(oldField);
        }
      }
    }
  };

  //Set Form Complaint Form
  const setComplaint = async () => {
    let msg;
    if (name.length === 0) {
      msg = "Debe ingresar un Nombre, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (lastName.length === 0) {
      msg = "Debe ingresar un Apellido, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (email.length === 0 && phone.length === 0) {
      msg = "Debe ingresar un correo o teléfono, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (item.length === 0) {
      msg = "De selecccionar un Tema, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (subject.length === 0) {
      msg = "De selecccionar un Asunto, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (rol.length === 0) {
      msg = "De selecccionar un Rol, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (area.length === 0) {
      msg = "De selecccionar una Área, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (message.length === 0) {
      msg = "Debe ingresar un mensaje, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }
    if (email.length === 0 && phone.length === 0) {
      msg = "Debe ingresar un correo o teléfono, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }

    if (phone.length > 0 && !/^\d{10}$/.test(phone)) {
      msg = "Debe ingresar un teléfono válido, para realizar el registro";
      swal("¡Atención!", msg, "warning");
      return;
    }

    try {
      $("#send").text("").addClass("disabled");
      $("#send").append('<i class="la la-refresh animated infinite rotateIn"></i> Procesando...');
      let formData = new FormData();
      let oldState = [...archivos];
      for (var x = 0; x < oldState.length; x++) {

        formData.append('file', oldState[x][0]);
      }
      formData.append("complaint", JSON.stringify(complaitn));
      const response = await axios({
        url: global.base_url + "complaint/register",
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data;'
        },
      });

      if (response.data.complaint_id) {
        swal(
          "¡Éxito!",
          "El registro se guardo correctamente con el número de Folio: " +
          response.data.complaint_id +
          " ",
          "success"
        ).then((value) => {
          window.location.reload();
        });
      } else {
        swal(
          "¡Oops¡",
          "Ocurrió un problema al realizar el registro. Intentalo nuevamente.",
          "warning"
        );
        $("#send").text("").removeClass("disabled");
        $("#send").append('Enviar...');
      }
    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {

    enterpriseModel(enterprise);
  }, []);

  if (stateEnterprise.length === 0) {
    return "";
  } else {
    return (
      <Fragment>
        <Header
          logo={stateEnterprise.logo}
          name={stateEnterprise.comercial_name}
        />
        <ModalComponent
          callback={close}
          name={stateEnterprise.comercial_name}
        />
        <section className="row mt-2">
          <div className="col-xs-12 col-md-1"></div>
          <div className="col-xs-12 col-md-10 d-flex align-items-center justify-content-center">
            <div className="col-xs-12 col-lg-10 col-md-10  box-shadow-2 p-0">
              <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                <div className="card-header border-0 pb-0">
                  <h2 className="card-subtitle text-center">
                    <span className="text-green-guay">
                      Estamos para servirte
                      <i className="la la-smile-o font-30"></i>
                    </span>
                  </h2>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <h4>
                          {" "}
                          Selecciona el asunto de acuerdo al tipo de mensaje
                          que deseas compartir.
                        </h4>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 text-center">
                        <div className="d-inline-block custom-control mr-1">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="envio"
                              id="envio"
                              onChange={getValue}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="envio"
                            >
                              Deseo que la información se maneje de manera
                              anónima
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Start - Ocultar seccion */}
                    <section hidden={complaitn.envio === 1 ? true : false}>
                      <div className="row mt-2 contact ">
                        <div className="col-md-12">
                          <h3 className="form-section text-base-guay">
                            <i className="la la-user"></i>Contacto
                          </h3>
                        </div>
                      </div>
                      <div className="row mt-2 contact ">
                        <div className="col-md-6">
                          <label>Nombre:</label>
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            name="name"
                            minLength="3"
                            maxLength="50"
                            onChange={getValue}
                            value={name}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Apellido:</label>
                          <input
                            type="text"
                            id="lastName"
                            className="form-control"
                            name="lastName"
                            minLength="3"
                            maxLength="50"
                            onChange={getValue}
                            value={lastName}
                          />
                        </div>
                      </div>
                      <div className="row mt-2 contact">
                        <div className="col-md-6">
                          <label>Correo:</label>
                          <input
                            type="email"
                            id="email"
                            className="form-control"
                            name="email"
                            onChange={getValue}
                            value={email}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Celular:</label>
                          <input
                            type="number"
                            id="phone"
                            className="form-control"
                            name="phone"
                            onChange={getValue}
                            value={phone}
                          />
                        </div>
                      </div>
                    </section>
                    {/* Start - Ocultar seccion */}

                    <div className="row mt-2">
                      <div className="col-md-12">
                        <h3 className="form-section text-base-guay">
                          <i className="la la-envelope"></i>Aportación
                        </h3>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label>Tema:</label>
                        <select
                          className="form-control"
                          name="item"
                          id="item"
                          onChange={getValue}
                        >
                          <option value="0">--Seleccione--</option>
                          {listItem.map((optionItem) => (
                            <option
                              key={optionItem.itemId}
                              value={optionItem.itemId}
                            >
                              {optionItem.description}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label>Asunto:</label>
                        <select
                          className="form-control"
                          name="subject"
                          id="subject"
                          onChange={getValue}
                        >
                          <option value="0">--Seleccione--</option>
                          {listSubject.map((optionSubject) => (
                            <option
                              key={optionSubject.subjectId}
                              value={optionSubject.subjectId}
                            >
                              {optionSubject.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label>¿Cuál es el rol de tu empresa?</label>
                        <select
                          className="form-control"
                          name="rol"
                          id="rol"
                          onChange={getValue}
                        >
                          <option value="0">--Seleccione--</option>
                          {listRol.map((optionRol) => (
                            <option
                              key={optionRol.rolId}
                              value={optionRol.rolId}
                            >
                              {optionRol.description}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label>Área:</label>
                        <select
                          className="form-control"
                          name="area"
                          id="area"
                          onChange={getValue}
                        >
                          <option value="0">--Seleccione--</option>
                          {listArea.map((optionArea) => (
                            <option
                              key={optionArea.areaId}
                              value={optionArea.areaId}
                            >
                              {optionArea.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label>Escribe tu aportación:</label>
                        <textarea
                          rows="4"
                          className="form-control"
                          name="message"
                          id="message"
                          onChange={getValue}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="wrap-file">
                          <div className="form-file">
                            <span className="pointer" onClick={renderFile}>
                              <input
                                type="file"
                                name="file"
                                id="file"
                                className="inpFile hidden"
                                onChange={onChangeHandler}
                              />
                              <i className="la la-chain text-green-guay"></i>
                              Adjuntar archivos (Máx 5)
                            </span>
                          </div>
                          <div>
                            <div className="row files-upload">
                              {listFiles.map((object, i) => (
                                <div
                                  className="col-md-10 cont-img"
                                  data-element={i}
                                  id={object.id}
                                  onClick={deleteFile}
                                  key={object.id}
                                >
                                  <p>
                                    <i className="la la-image text-success"></i>
                                    {object.name}
                                    <i className="la la-remove text-danger pointer"></i>
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <Recaptcha
                          sitekey="6LfUNukUAAAAAJk7Tsq9G8uJNyu1tI2XBZNUE09J"
                          onResolved={() => console.log("Human detected.")}
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          id="send"
                          type="submit"
                          className="btn btn-pink btn-lg btn-block"
                          onClick={setComplaint}
                        >
                          Enviar
                        </button>
                      </div>
                    </div>

                  </div>
                  Powered by <img src="../img/logo_guay_color.png" className="w-7 " alt="logo guay" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-1"></div>
        </section>
      </Fragment>
    );
  }
};

export default Register;

import React,{ useState } from "react";
import Modal from 'react-modal';


const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
      
    }
  };

const ModalComponent = (props) =>{
    var subtitle;
    const [modalIsOpen,setIsOpen] = useState(true);

    function afterOpenModal() {
        subtitle.style.text = 'center';
      }
     
      function closeModal(){
        setIsOpen(false)
        props.callback();
      }
    return(
        <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Canal de Denuncia guay"
          overlayClassName="Overlay"
        >
 
          <p className="text-center font-large-2 " ref={_subtitle => (subtitle = _subtitle)}>!Bienvenido¡</p>
          <div className="modal-body">
         <h3 className="text-center"> Agradecemos tu participación para nosotros<br/> 
          es muy importante, por medio de nuestro canal nos <br/> 
          aseguraremos que la empresa <b>{props.name}</b>  <br/>
          reciba de forma segura tu denuncia,<br/>
           queja o felicitación. </h3>
          </div>
          
          <div className="text-center">
          <button
             type="button"
             className="btn btn-info btn-lg btn-block"
             onClick={closeModal}
             >Aceptar y Continuar
             </button>
          </div>
          
        </Modal>
      </div>           
                            
    );

}


export default ModalComponent;